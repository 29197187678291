



































import { defineComponent } from "@vue/composition-api";
import { Cell, CellGroup, List, Popup, PullRefresh, Toast } from "vant";
import axios from "@/helpers/axios";

export default defineComponent({
  components: {
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Popup.name]: Popup
  },
  data() {
    return {
      list: [],
      detail: {},
      page: -1,

      showDetail: false,
      refreshing: false,
      finished: false,
      loading: false
    };
  },
  created() {
    this.onRefresh();
  },
  methods: {
    onRefresh() {
      this.page = -1;
      window.scrollTo(0, 0);
      this.getData(true);
    },
    onLoad() {
      this.getData();
    },
    async getData(isRefresh = false) {
      const size = 20;
      const res = await axios
        .post("/api/manage/invoice/list", {
          page: this.page + 1,
          size: size
        })
        .then(res => res?.data.data)
        .catch(() => undefined);
      this.loading = false;
      this.refreshing = false;
      if (!res) {
        Toast.fail("数据获取失败");
        return;
      }
      this.finished = res.list.length < size;

      this.page++;
      this.list = isRefresh ? res.list : this.list.concat(res.list);
    },
    toDetail(detail: {}) {
      this.detail = detail;
      this.showDetail = true;
    }
  }
});
